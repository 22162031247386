import { graphql } from 'react-relay'

export const AuthorizationFragment = graphql`
    fragment authorization_authorization on Authorization {
        roles {
            isPreviewingContentAllowance
            isPreviewing
            isAnonymous
            hasPremiumSubscription
            isFreemium
        }
    }
`

export const ContentPieceFragment = graphql`
    fragment authorization_contentPiece on ContentPieceInterface {
        isPremium
        contentPieceType {
            isAvailableForFreemium
        }
    }
`

export const ContentFragment = graphql`
    fragment authorization_content on Content {
        anonymousUserHasPremium
    }
`
