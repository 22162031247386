import React from 'react'

import Pill from './Pill'

const ContentLockedPill = () => {
    return (
        <Pill
            icon="RiLock2Fill"
            iconSize="1rem"
            ariaLabel="Content piece locked"
            data-cy="content-locked-icon"
            padding="0.5rem"
            variant="rounded-rectangle"
            display="inherit"
        />
    )
}

export default ContentLockedPill
