import styled from 'styled-components'

import Pill from './Pill'

import { fonts, trispace } from 'theme'

/**
 *
 * @param children the content type to show on the pill.
 * @returns a pill used to show content type on the top left of content cards.
 */
const ContentTypePill = ({ children: contentType }: { children: string }) => {
    return (
        <StyledPill
            ariaLabel={contentType}
            fontSize={fonts.size.XXS}
            variant="rectangular"
            fontFamily={trispace.style.fontFamily}
            data-cy={`${contentType}-content-type-pill`}
        >
            {contentType}
        </StyledPill>
    )
}

const StyledPill = styled(Pill)`
    top: 0.75rem;
    left: 0.75rem;
`

export default ContentTypePill
