import getUsesSquareImage from './getUsesSquareImage'

// This is a helper function that returns the object-position property for the image based on the content type.
// This is needed because currently Guides and Packs (NB: `contentPieceType.name: "Pack"` or `contentPieceType.name: "Guide"`, these both have `contentPieceType.publicName: "Guide"`) have square images, which are being cut off when displayed in the search results.
// The images tend to have titles at the top so we want to display the top of the image
const getImageObjectPositionBasedOnContentType = (contentType: string) => {
    if (getUsesSquareImage(contentType)) return 'top'

    return 'center'
}

export default getImageObjectPositionBasedOnContentType
