import { transparentize } from 'polished'
import type { IconBaseProps } from 'react-icons'
import styled, { css } from 'styled-components'

import type { IconKeys } from 'components/Icon'
import Icon from 'components/Icon'
import type { ParagraphProps } from 'components/Paragraph'
import Paragraph from 'components/Paragraph'
import type { BoxProps } from 'components/Primitives/Box'
import Box from 'components/Primitives/Box'
import type { SizeProps } from 'components/types/props'
import { fonts } from 'theme'
import type { ThemedComponent } from 'theme'
import type { ColorName } from 'theme'
import type { RequiredPick } from 'types/RequiredPick'

type Variant = 'rounded' | 'rectangular' | 'rounded-rectangle'

type IconProps = {
    /**
     * Name of the icon to be used. If only passing an icon, then an aria-label should also be added.
     */
    icon?: IconKeys
    iconSize?: SizeProps | string
    iconStroke?: ColorName
    iconStrokeWidth?: IconBaseProps['strokeWidth']
}

type AccessibilityProps = {
    id?: string
    /**
     * Accessible label for the Pill.
     */
    ariaLabel?: string
}

export type PillProps = IconProps &
    AccessibilityProps &
    Omit<BoxProps, 'fontSize'> &
    Pick<ParagraphProps, 'fontSize'> & {
        /**
         * Text to be displayed on the Pill.
         */
        children?: string
        variant?: Variant
    }

const Pill = ({
    icon,
    iconSize = 'default',
    iconStroke,
    iconStrokeWidth,
    children,
    ariaLabel,
    backgroundColor = 'neutral.0',
    borderColor = 'neutral.200',
    color = 'neutral.999',
    variant = 'rounded',
    fontSize = fonts.size.XS,
    ...props
}: PillProps) => {
    const iconOnly = icon && !children

    return (
        <Container
            {...props}
            variant={variant}
            backgroundColor={backgroundColor}
            borderColor={borderColor}
            iconOnly={iconOnly}
        >
            {icon && (
                <Icon
                    icon={icon}
                    aria-label={ariaLabel}
                    color={color}
                    stroke={iconStroke}
                    strokeWidth={iconStrokeWidth}
                    margin="auto"
                    size={iconSize}
                    marginRight={!!children ? '0.3rem' : undefined}
                />
            )}
            {children && (
                <Paragraph fontSize={fontSize} m="auto" color={color}>
                    {children}
                </Paragraph>
            )}
        </Container>
    )
}

type StyledBoxProps = ThemedComponent<BoxProps & RequiredPick<PillProps, 'variant'>> & {
    iconOnly: boolean
}

const Container = styled(Box)<StyledBoxProps>`
    width: fit-content;
    display: flex;
    justify-content: center;

    ${({ variant, theme, iconOnly }) => {
        switch (variant) {
            case 'rectangular':
                return css`
                    padding: 0.2rem 0.375rem;
                    text-transform: capitalize;
                `
            case 'rounded-rectangle':
                return css`
                    padding: 0.15rem 0.35rem;
                    border-radius: ${theme.radii.default};
                    text-transform: capitalize;
                `
            case 'rounded':
            default:
                // If the pill is just an icon, we want it to be a circle
                const padding = iconOnly ? '0.5rem' : '0.25rem 0.625rem'
                return css`
                    padding: ${padding};
                    border-radius: ${theme.radii.large};
                `
        }
    }}

    box-shadow: 0px 0px 1px ${({ theme }) =>
        transparentize(0.72, theme.palette.neutral[999])} inset;

    // Fix for border bleed (https://bugzilla.mozilla.org/show_bug.cgi?id=921341)
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
`

export default Pill
