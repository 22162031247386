/**
 *
 * To be used as a React hydration safe replacement for Date.now()
 *
 * Date.now can return different values on the clinet vs the server, so can cause occasional
 * date issues. This uses UTC time to standardise the value returned, which should be OK for most cases
 *
 */
const getUTCCurrentDate = () => {
    const unsafeDateNow = new Date()
    return Date.UTC(
        unsafeDateNow.getUTCFullYear(),
        unsafeDateNow.getUTCMonth(),
        unsafeDateNow.getUTCDate(),
        unsafeDateNow.getUTCHours(),
        unsafeDateNow.getUTCMinutes(),
        unsafeDateNow.getUTCSeconds(),
        unsafeDateNow.getUTCMilliseconds(),
    )
}

export default getUTCCurrentDate
