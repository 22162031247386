import React from 'react'

import Pill from './Pill'

const PremiumContentCrownPill = () => {
    return (
        <Pill
            icon="RiVipCrown2Fill"
            iconStroke="neutral.999"
            iconStrokeWidth="2"
            color="highlight.500"
            iconSize="1rem"
            ariaLabel="Premium content"
            data-cy="content-locked-icon"
            padding="0.5rem"
            variant="rounded-rectangle"
            display="inherit"
        />
    )
}

export default PremiumContentCrownPill
