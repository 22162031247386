import { parseISO } from 'date-fns'
import formatDistance from 'date-fns/formatDistance'

/*

    Nicely return a string saying how long ago the time was

*/

const timeAgo = (date: string): string => {
    return formatDistance(parseISO(date), new Date())
}

export default timeAgo
