import { parseISO, subDays } from 'date-fns'

import getUTCCurrentDate from 'lib/getUTCCurrentDate'
import timeAgo from 'lib/timeAgo'

export const formatShortDate = (dateString: string) => {
    const date = new Date(dateString)

    const nowUtc = getUTCCurrentDate()

    const fiveDaysAgo = subDays(nowUtc, 5)
    const parsedDate = parseISO(dateString)

    if (fiveDaysAgo < parsedDate) {
        return `${timeAgo(dateString)} ago`
    }

    return date.toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        timeZone: 'UTC',
    })
}
